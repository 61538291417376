$(document).on('turbolinks:load ready', function() {
  $('.numeric_length').on('keypress', function(e) {
    if ($(this).val().length > 3) {
      e.preventDefault();
      return false;
    }
  });

  /**
   * This event is used to format the mobile number as
   * USA standard.
   */

  $('input[type="tel"]').on('keypress', function() {
    var numbers = this.value.replace(/\D/g, ''),
    char = { 0: '(', 3: ') ', 6: ' - ' };
    this.value = '';
    for (var i = 0; i < numbers.length; i++) {
      this.value += (char[i] || '') + numbers[i];
    }
  });

  $('#sidebarCollapse').on('click', function() {
    $('#sidebar').toggleClass('active');
    $(this).toggleClass('open');
    $('body > .wrapper').toggleClass('sidebar_open');
    $('body > .wrapper').toggleClass('sidebar_closed');
  });
  $('[data-toggle="tooltip"]').tooltip();

  if ( $('[type="date"]').prop('type') != 'date' ) {
    $('.datepicker').datetimepicker({
      format: 'YYYY/MM/DD'
    });
    $('.datetimepicker input[type="date"]').on('blur', function() { $(this).parents('.datetimepicker').datetimepicker('hide')});
  }

  $('#scroll-to-signup').on('click', function() {
    $('html, body').animate({ scrollTop: $('#signup-thumbnails').offset().top }, 500);
  });

  preview_file_name();

  $('.local-form-submission').on('click', function() {
    $('.form').attr('data-remote', false);
  });

  $('.dropdown-toggle').dropdown();

  // prevent click on disabled anchors
  $('a.disabled').on('click', function(e) { e.preventDefault(); });
});

$(document).on('cocoon:after-insert', function() {
  preview_file_name();
});

function preview_file_name() {
  $('.browse-file input[type="file"]').change(function(e){
    var fileName = e.target.files[0].name;
    $(this).parents('.browse-file').find('.file-name').html(fileName);
  });
}
