window.PatientList = {
  runValidations: function() {
    $('#marketplace_form').on('submit', function() {
      $('.validation-error').remove();
      numberOfPatients = parseInt($('#no_of_patients').val());
      travelDistance = parseInt($('#travel_distance').val());
      zipcode = $('#distance_from_zip_code').val();
      providerName = $('#provider_name').val();
      invitationType = $('#invitation_type').val();
      privateUrl = $('#private_url').val().trim();
      fromAge = $('#from_age').val();
      toAge = $('#to_age').val();

      if (parseInt(fromAge) > parseInt(toAge)) {
        $('#from_age').after("<span class='validation-error'>must be less then or equal to 'To' age</span>");
      }

      if (!numberOfPatients || numberOfPatients > 9999) {
        $('#no_of_patients').after("<span class='validation-error'>must be at between 1 and 9999</span>");
      }

      if (!!travelDistance && !zipcode) {
        $('#distance_from_zip_code').after("<span class='validation-error'>can't be blank</span>");
      }

      if (!providerName) {
        $('#provider_name').after("<span class='validation-error'>can't be blank</span>");
      }

      if (!invitationType) {
        $('#invitation_type').after("<span class='validation-error'>can't be blank</span>");
      }

      if (invitationType == 'Private Clinic' && !privateUrl) {
        $('#private_url').after("<span class='validation-error'>can't be blank</span>");
      }

      if ($('.validation-error').length) {
        inputId = $('.validation-error').first().parents('.form-group').attr('id');
        $('html, body').animate({ scrollTop: $(`#${inputId}`).offset().top }, 500);
        return false;
      }
    });
  },
  generateAnother: function() {
    $("#new_list").on('click', function() {
      $('#results').addClass('d-none');
      $('#filters').removeClass('d-none');
      $('#results').empty();
    });
  }
}
