$(document).on('turbolinks:load', function() {
  bind_fancy_squares();
  bind_fancy_buttons();
})

function bind_fancy_squares() {
  $('.fancy-square li a').click(function() {
    var parentUl = $(this).parents('ul').first();
    var hiddenField = $(`#${parentUl.data('fieldId')}`);
    $(this).toggleClass('active');
    var selections = hiddenField.val() || '';
    if ($(this).hasClass('active')) {
      selections = selections.concat(`,${$(this).data('value')}`);
    } else {
      selections = selections.replaceAll($(this).data('value'), '');
    }
    hiddenField.val(selections);
  });
}
function bind_fancy_buttons() {
  $('.fancy-btns li a').click(function() {
    var parentUl = $(this).parents('ul').first();
    parentUl.find('a').removeClass('active');
    $(this).addClass('active');
    $(`#${parentUl.data('fieldId')}`).val($(this).data('fieldValue'));
  });
}
