// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("./places")
require("./conditional_fields")
require("./patient_signature")
require("./volunteer")
require("./fancy_fields")
require("./provider_enrollments/vaccine_orders")
require("./provider_enrollments/vaccine_records")
require("./message_board/message")
require("./provider_enrollments/address")
require("./two_fa_reports")
require("./common")
require("./export")
require("./schedule")
require("./patient_list")
require("@nathanvda/cocoon")
require("tempusdominus-bootstrap-4")
require("chartkick").use(require("highcharts"))
require("chart.js")
require("./password_strength")
require("./admin_audits")

import $ from 'jquery';
global.$ = jQuery;

import select2 from 'select2';
require("./select2")
require("./bulk_email")

import "bootstrap"
import "@fortawesome/fontawesome-free/js/all"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
