window.initAutocomplete = function(selector) {
  // temporarily not using Google maps Api
  return;

  $(selector).on('keypress', function(event) {
    if(event.keyCode == 13) {
      event.preventDefault();
      event.target.blur();
      return false;
    }
  });

  $(selector).on('keyup', function() {
    var autocomplete = new google.maps.places.Autocomplete($(this).get(0))
    var element = $(this);
    if (!element.parents('.address-group').length) { return; }

    autocomplete.addListener('place_changed', function() {
      var place = autocomplete.getPlace();

      // clear fields
      element.parents('.address-group').first().find(`input:not(${selector})`).val('');

      var streetField = element.parents('.address-group').first().find('.street_number');

      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];

        if (addressType == 'route' && streetField.length) {
          val = [streetField.val(), place.address_components[i]['short_name']].filter(Boolean).join(' ');
          streetField.val(val);
          continue;
        }

        addrElement = element.parents('.address-group').first().find(`.${addressType}`);
        componentName = addrElement.data('component') || 'long_name';
        addrElement.val(place.address_components[i][componentName]);
      }
    });
  });
}

function autocomplete_zip() {
  $('.autocomplete-zip').on('keyup', function() {
    value = $(this).val();
    if (value.length != 5) { return; }

    zip_field = $(this);
    $.getJSON(`/zip_codes?zip_code=${value}`, function(response) {
      if (response) {
        zip_field.parents('.address-group').first().find('.city').val(response.city);
        zip_field.parents('.address-group').first().find('.state').val(response.state_code);
        zip_field.parents('.form-group').first().find('.text-secondary').remove();
      } else {
        if (!zip_field.parents('.form-group').find('.text-secondary').length) {
          zip_field.parents('.form-group').append('<div class="text-secondary">zipcode not recognized, unable to fill address</div>');
          setTimeout(function() {
            zip_field.parents('.form-group').first().find('.text-secondary').remove();
          }, 3000);
        }
      }
    });
  });
}

$(document).on('turbolinks:load', function() {
  autocomplete_zip();
});
