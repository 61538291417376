window.bind_location_select = function() {
  $('#location_select2').on('change', handleLocationSelectChange);
}

function handleLocationSelectChange(event) {
  let target = $(event.target);
  let emailField = $('#email_select2');
  if (target.data('type') == 'clinic_site') {
    var controller = 'venues'
  } else if (target.data('type') == 'on_site') {
    var controller = 'businesses'
  }

  $.ajax({
    url: `/${controller}/user_emails`,
    method: 'GET',
    data: { venue_ids: target.val() },
    success: function(data) {
      emailField.empty().append(data.map((email) => new Option(email, email, false, false)));
    }
  });
}

window.bind_occupation_or_priority_group_select = function() {
  $('#occupation_select2').on('change', handleOccupationOrPriorityGroupChange);
  $('#priority_group_select2').on('change', handleOccupationOrPriorityGroupChange);
}

function handleOccupationOrPriorityGroupChange(event) {
  let occupations = $('#occupation_select2').val();
  let priorityGroups = $('#priority_group_select2').val();
  let emailField = $('#email_select2');

  $.ajax({
    url: '/pre_registrations/user_emails',
    method: 'GET',
    data: {occupation_ids: occupations, priority_groups: priorityGroups},
    success: function(data) {
      emailField.empty().append(data.map((email) => new Option(email, email, false, false)));
    }
  });
}

window.bind_organization_select = function() {
  $('#organization_select2').on('change', handleOrganizationChange);
}

function handleOrganizationChange(event) {
  let organizations = $('#organization_select2').val();
  let locationField = $('#provider_locations_select2');
  let emailField = $('#email_select2');

  $.ajax({
    url: '/provider_enrollments/filter_locations',
    method: 'GET',
    data: {organization_ids: organizations},
    success: function(data) {
      locationField.empty();

      if(data.locations?.length > 0) {
        locationField.append(data.locations.map((obj) => new Option(obj[0], obj[1], false, false)));
      }

      emailField.empty().append(data.emails.map((email) => new Option(email, email, false, false)));
    }
  });
}

window.bind_location_filter = function() {
  $('#provider_locations_select2').on('change', handleLocationFilterChange);
}

function handleLocationFilterChange(event) {
  let locations = $('#provider_locations_select2').val();
  let emailField = $('#email_select2');

  $.ajax({
    url: '/provider_enrollments/user_emails',
    method: 'GET',
    data: {location_ids: locations},
    success: function(data) {
      emailField.empty().append(data.map((email) => new Option(email, email, false, false)));
    }
  });
}

window.bind_dismiss_modal = function() {
  $('#bulk-email-modal').on('hidden.bs.modal', function() {
    $('.reset_filters').click();
    $('.bulk-email-modal').find('textarea').val('');
  })
}
