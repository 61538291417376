window.ReplicateAddress = {
  init: function () {
    var that = this;
    $('#replicate_link').change(function (e) {
      if ($(this).prop('checked')) {
        that.replicate_address();
      } else {
        that.remove_address();
      }
    });
  },
  replicate_address: function () {
    $('.address-secondary').find('input:not([type="hidden"]), select').each(function () {
      var id = ['#', $(this).attr('id').replaceAll('admin', 'ship')].join('');
      $(this).val($('.address-main').find(id).val());
    })
  },
  remove_address: function () {
    $('.address-secondary').find('input, select').val('');
  }
}
