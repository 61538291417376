$(document).on('turbolinks:load', function() {
  $('.select2').select2({
    'placeholder': $(this).data('placeholder') || 'Any',
    'width': '100%'
  });

  $('.reset_select2').on('click', function(){
    $select = $(`#${$(this).data('target')}`);
    clearSelection($select);
  });

  $('.select-all').on('click', function(){
    $select = $(`#${$(this).data('target')}`);
    $select.find(' option').prop('selected', true);
    $select.trigger('change.select2').trigger('change');
  });

  $('.reset_filters').on('click', function(){
    $select = $(`#${$(this).data('target')}`);
    clearSelection($select);
    $select.trigger('change');
  });
});

window.bind_select2 = () => {
  $('.select2').select2({
    'width': '100%'
  });
}

function clearSelection(select) {
  select.find(' option').prop('selected', function(){ return this.defaultSelected; });
  select.trigger('change.select2');
}
