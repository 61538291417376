$(document).on('turbolinks:load', function () {
  if ($('.password-strength')){
    $('.password-strength').focus(function(){
      $("#password-message").show();
    });

    $('.password-strength').blur(function() {
      $("#password-message").hide();
    });

    $('.submit-password').click(function(event) {
      if ($('.invalid').length > 0){
        $("#password-message").show();
        event.preventDefault();
      }
    });

    $('.password-strength').on('keyup paste', function() {
      setTimeout(function() {
        lowerCaseLetters = /^(?=.*[a-z])/;
        if($('.password-strength').val().match(lowerCaseLetters)) {
          $('#lowercase').removeClass("invalid");
          $('#lowercase').addClass("valid");
        } else {
          $('#lowercase').removeClass("valid");
          $('#lowercase').addClass("invalid");
        }

        upperCaseLetters = /^(?=.*[A-Z])/;
        if($('.password-strength').val().match(upperCaseLetters)) {
          $('#uppercase').removeClass("invalid");
          $('#uppercase').addClass("valid");
        } else {
          $('#uppercase').removeClass("valid");
          $('#uppercase').addClass("invalid");
        }

        numbers = /^(?=.*[0-9])/;
        if($('.password-strength').val().match(numbers)) {
          $('#digit').removeClass("invalid");
          $('#digit').addClass("valid");
        } else {
          $('#digit').removeClass("valid");
          $('#digit').addClass("invalid");
        }

        character_length = /^(?=.{12,})/
        if($('.password-strength').val().match(character_length)) {
          $('#length').removeClass("invalid");
          $('#length').addClass("valid");
        } else {
          $('#length').removeClass("valid");
          $('#length').addClass("invalid");
        }

        characters = /^(?=.*[%&*$!@#])/;
        if($('.password-strength').val().match(characters)) {
          $('#character').removeClass("invalid");
          $('#character').addClass("valid");
        } else {
          $('#character').removeClass("valid");
          $('#character').addClass("invalid");
        }
      }, 50);
    });
  }
});
