$(document).on('turbolinks:load', function() {
  function validateForm() {
    var value = $('#message-field').val();
    var subject = $('#message-subject-field').val();
    var email = $('#message-email-field').val();
    var hasClass = !$('.email-error').hasClass('d-none');

    if (!value || value.length <= 4) {
      $('.send-email').prop('disabled', true);
    }
    else if (value.length >= 4 && email && email.length > 4 && !hasClass && subject.length >= 2) {
      $('.send-email').prop('disabled', false);
    }
  }

  $('#message-email-field').on('focusout', function() {
    var data = {
      type: $('#message-type-field').val(),
      email: $('#message-email-field').val()
    }

    $.ajax({
      url: `/admin/message_board/verify_email`,
      method: 'POST',
      data: data
    }).done(function(response) {
      if (!response.email_present) {
        $('.email-error').removeClass('d-none');
        $('.send-email').prop('disabled', true);
      } else {
        validateForm();
      }
    });
  });

  $('#message-email-field').on('focus', function() {
    $('.email-error').addClass('d-none');
    $('.send-email').prop('disabled', true);
  });

  $('#message-field').on('input', function(te) {
    validateForm();
  });

  $('#new_message').on('click', function() {
    $('#new-message-modal').modal('show');
  })

  $('#bulk_email').on('click', function() {
    $('#bulk-email-modal').modal('show');
  })

  if( $('#messages').length ){
    var rows = document.getElementsByClassName('row');
    var row = rows[rows.length-1];
    document.getElementById('messages').scrollTop = row.offsetTop;
  }

  $('#new-message-modal').on('hidden.bs.modal', function() {
    $(this).find('.modal-body input,textarea').val('');
    $(this).find('.email-error').addClass('d-none');
  });
});
