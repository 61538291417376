$(document).on("turbolinks:load", function() {
  handle_modal_submit();
});

function handle_modal_submit() {
  $('#modal_submit_btn').on("click", function() {
    $('#two_fa_code').val($('#code_2fa').val());
    $('#reports_form')[0].requestSubmit();
  });

   $("#twoFaModal").on("hidden.bs.modal", function() {
     $('#code_2fa').val('');
     $('#two_fa_code').val('');
   });
}
