window.bind_shift = function() {
  $('.shift-data').on('click', function() {
    shiftId = $(this).data('shiftId');
    day = $(this).data('date');

    if(shiftId) {
      $.ajax({
        url: `/admin/shifts/${shiftId}/edit?day=${day}`,
        method: 'GET'
      });
    } else {
      alert('Something went wrong!');
    }
  })
}

window.bind_new_user_shift = function() {
  $('.user-shift-data').on('click', function() {
    shiftId = $(this).data('shiftId');
    day = $(this).data('date');
    workerId = $(this).data('workerId');
    shiftAction = $(this).data('shiftAction');

    if(shiftId) {
      $.ajax({
        url: `/shifts/${shiftId}/edit?day=${day}&worker_id=${workerId}&shift_action=${shiftAction}`,
        method: 'GET'
      });
    } else {
      alert('Something went wrong!');
    }
  })
}

window.bind_shift_modal = function(e) {
  shiftId = e.data('shiftId');
  day = e.data('date');
  workerId = e.data('workerId');
  shiftAction = e.data('shiftAction');

  if(shiftId) {
    $.ajax({
      url: `/shifts/${shiftId}/edit?day=${day}&worker_id=${workerId}&shift_action=${shiftAction}`,
      method: 'GET'
    });
  }
}

window.bind_week_select = function() {
  $('.week-select').on('change', () => {
    $('.submit-btn').click();
  });
}

window.bind_shift_filters = () => {
  $('.worker-filter').on('change', (e) => {
    let target = $(e.currentTarget);
    let shift_id = target.data('shift-id')
    let available_times = $('.by-availability').val();
    let role_id = $('.by-role').val();

    $('.workers-select').html('');

    $.ajax({
      url: `/admin/shifts/filter_workers?available_times=${available_times}&role_id=${role_id}&id=${shift_id}`,
      method: 'GET',
      success: function (workers) {
        $('.workers-select').append(new Option('-- Select --', ''));
        $.each(workers, function (e) {
          $('.workers-select').append(new Option(workers[e][0], workers[e][1]));
        });
      }
    });
  });
}

window.bind_invite = () => {
  $('.invite-workers').on('click', (e) => {
    let target = $(e.currentTarget);
    let clinic_id = target.data('clinic-id');

    if(clinic_id) {
      $.ajax({
        url: `/admin/clinics/${clinic_id}/invite_workers`,
        method: 'GET'
      });
    } else {
      alert('Something went wrong!');
    }
  })
}

window.bind_invite_filters = () => {
  $('.workers-select > option').prop('selected', 'selected');
  bind_select2();

  $('.worker-filter').on('change', (e) => {
    let target = $(e.currentTarget);
    let clinic_id = target.data('clinic-id');
    let available_times = $('.by-availability').val();
    let role_id = $('.by-role').val();

    $('.workers-select').html('');

    $.ajax({
      url: `/admin/clinics/filter_workers?available_times=${available_times}&role_id=${role_id}&id=${clinic_id}`,
      method: 'GET',
      success: function (workers) {
        $.each(workers, function (e) {
          $('.workers-select').append(new Option(workers[e][0], workers[e][1]));
        });

        $('.workers-select > option').prop('selected', 'selected');
        bind_select2();
      }
    });
  });
}

$(document).on('turbolinks:load', function() {
  $('.panel-collapse').on('show.bs.collapse', function () {
    $($(this).siblings('.panel-header').find('.fa-chevron-down')).addClass('d-none');
    $($(this).siblings('.panel-header').find('.fa-chevron-up')).removeClass('d-none');
  });

  $('.panel-collapse').on('hide.bs.collapse', function () {
    $($(this).siblings('.panel-header').find('.fa-chevron-down')).removeClass('d-none');
    $($(this).siblings('.panel-header').find('.fa-chevron-up')).addClass('d-none');
  });
});
