// Select/unselect all for order approval and vtrcks report generation
$("#orders_selection_all").on("change", function() {
  $(this).prop("checked", !$(this).prop("checked"));

  $.each($("input[name^='orders_selection']"), function() {
    $(this).prop("checked", !$(this).prop("checked"));
  });
});

$("#approval_selection_all").on("change", function() {
  if ($(this).prop("checked")) {
    $('.approval_selection').prop("checked", true);
    $('.vaccine-for-approval').attr('required', true);
  } else {
    $('.approval_selection').prop("checked", false);
    $('.vaccine-for-approval').attr('required', null);
  }
});

// set vaccine type to be required when marked for approval
$('input[type="checkbox"][name="approval_selection[]"').on("change", function() {
  selectField = $(`select#vaccine_order_${$(this).val()}_vaccine_id`);

  if ($(this).prop("checked")) {
    selectField.attr('required', true);
  } else {
    $('#approval_selection_all').prop("checked", false);
    selectField.attr('required', null);
  }
});

$('.state_approval').on('click', function() {
  if ($(this).is(':checked'))
    checked = true;
  else
    checked = false;

  $.ajax({
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    },
    method: "PATCH",
    url: `/admin/vaccine_orders/${$(this).data('id')}/update_state_approval`,
    data: { checked: checked }
  });
});

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

$(document).on('turbolinks:load', function() {
  $(document).on('change', '[data-js=form_ajax_trigger]', function(event) {

    $target      = $(event.target);
    tar_value    = $target.val();
    group        = $target.data('group') || "";
    source       = $target.data('source');
    destination  = $target.data('destination');
    $destination = $(`[data-id=${destination}]`);
    path        = $target.data('path');

    params = { source: source };
    if(source == 'vaccine_name')
      params.query = { name: tar_value }
    else if(source == 'vaccine_description')
      params.query = {
        description: tar_value,
        name: $(`[data-js=form_ajax_trigger][data-id=vaccine_name_${group}]`).val()
      }
    else if(source == 'vaccine_id')
      params.query = { id: tar_value }
    else if(source == 'vaccine_lot_no')
      params.query = {
        lot_no: tar_value,
        id: $(`[data-js=form_ajax_trigger][data-id=vaccine_id_${group}]`).val()
      }

    $.get(path, params)
      .done(function(data) {
        options = '<option value></option>';

        if(source == 'vaccine_name')
          data.forEach(value => options += `<option value="${value}">${value}</option>`);
        else if(source == 'vaccine_description')
          data.forEach(value => options += `<option value="${value[0]}">${value[1]}</option>`);
        else if(source == 'vaccine_id')
          data.forEach(value => options += `<option value="${value}">${value}</option>`);
        else if(source == 'vaccine_lot_no')
          data.forEach(value => options += `<option value="${value[0]}">${value[1]}</option>`);

        $destination.html(options);
      });
  });

  $("#vaccine_order_vaccine_name").on('change',function(){
    vaccine = $(this).val();
    if (vaccine != "Pfizer COVID-19 Vaccine"){
      $("#vaccine_order_doses_requested").siblings(".invalid-feedback").remove();
      $("#vaccine_order_doses_requested").removeClass("is-invalid");
    }
  })

////// CLEARS SEARCH/FILTERS ON ADMIN::INDEX
  $('#clear_filters').on('click', function(){
    $('#provider_type_select2').val('').trigger('change');
    $('#status_select2').val('').trigger('change');
    $('#vaccine_type_select2').val('').trigger('change');
    $('#populations_served_select2').val('').trigger('change');
    $('#org_name_search_input').val('');
    $('#request_date_from').val('');
    $('#request_date_to').val('');

    $(this).closest('form').submit();
  });
});
