window.bind_signature_pad = function(field_name) {
  var signaturePad = setCanvas(field_name);
  hidden_field = $(`#hidden_${field_name}_data`);
  if (hidden_field.val()) { signaturePad.fromDataURL(hidden_field.val()); }

  $(`#clear_${field_name}`).on('click', function() {
    signaturePad.clear();
    $("#hidden_signature_points").val(0);
  });

  $('form').on('submit', function() {
    if (signaturePad.isEmpty()) {
      $(`#hidden_${field_name}_data`).val('');
      $("#hidden_signature_points").val(0);
      return;
    }
    var points = signaturePad.toData().reduce(function (total, group) {
      return group.length + total;
    }, 0);

    var exisitingPoints = parseInt($("#hidden_signature_points").val());
    $("#hidden_signature_points").val(points + exisitingPoints);
    $(`#hidden_${field_name}_data`).val(signaturePad.toDataURL());
  });

  var toggleSignatureMethod = $('.toggle_signature_method');
  var signeeNameBlock = $('#signee_name_block');
  var signatureBlock = $('#signature_block');

  if ($('#signee_name_field').length && $('#signee_name_field').val().length) {
    signatureBlock.addClass('d-none');
    signeeNameBlock.removeClass('d-none');
    toggleSignatureMethod.html($('#sign_using_canvas_text').text());
  } else {
    signeeNameBlock.addClass('d-none');
    signatureBlock.removeClass('d-none');
    $('#signee_name_field').val('');
    toggleSignatureMethod.html($('#sign_by_typing_name_text').text());
  }

  toggleSignatureMethod.on('click', function() {
    if (signeeNameBlock.hasClass('d-none')) {
      signatureBlock.addClass('d-none');
      signeeNameBlock.removeClass('d-none');
      signaturePad.clear();
      toggleSignatureMethod.html($('#sign_using_canvas_text').text());
    } else {
      signeeNameBlock.addClass('d-none');
      signatureBlock.removeClass('d-none');
      $('#signee_name_field').val('');
      toggleSignatureMethod.html($('#sign_by_typing_name_text').text());
    }
  });
}

function setCanvas(field_name) {
  var canvas = document.querySelector(`#${field_name}_block canvas`);
  var ratio =  Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);

  var signaturePad = new SignaturePad(canvas);
  return signaturePad;
}
