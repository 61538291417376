window.Export = {
  refresh_page: function (prevent_refresh_id, path) {
    if ($(`#${prevent_refresh_id}`).val() === 'false') {
      $.ajax({
        url: path,
        method: 'GET'
      });

      var that = this;
      setTimeout(function () {
        that.refresh_page(prevent_refresh_id, path);
      }, 5000);
    }
  }
}
