function bind_conditional_fields() {
  $('select.conditional-source').on('change', sync_conditional_fields);
  $('input[type=checkbox].conditional-source, input[type=radio].conditional-source').on('change', handleConditionalSourceChange);
}

function sync_conditional_fields() {
  $('.conditional-data').hide();

  $('select.conditional-source').each(function() {
    var selected_option = $(this).find('option:selected').text().replace(/[$-/:-?{-~!"^_`\[\]\s]/g, '');
    $(`[data-source-id=${$(this).attr('id')}]`).each(function() {
      if ($(this).attr('data-source-value').split('|').includes(selected_option)) {
        $(this).show();
      } else {
        $(this).find('input').val('');
      }
    });
  });

  $('input[type=checkbox].conditional-source, input[type=radio].conditional-source').each(function() {
    if($(this).is(':checked')) {
      $(`[data-source-id=${$(this).attr('id')}][data-source-value='true']`).show()
    } else {
      $(`[data-source-id=${$(this).attr('id')}][data-source-value='false']`).show()
    }
  });
}

function handleConditionalSourceChange(e) {
  if(this.checked) {
    $(`[data-source-id=${this.id}][data-source-value='false'] input`).val('');
    $(`[data-source-id=${this.id}][data-source-value='true'] input`).val('');
  } else {
    $(`[data-source-id=${this.id}][data-source-value='true'] input`).val('');
    $(`[data-source-id=${this.id}][data-source-value='false'] input`).val('');
  }
  sync_conditional_fields();
}

function assign_nested_conditional_field_ids() {
  assign_data_source_ids_to_attachment_fields();
  assign_data_source_ids_to_facility_fields();
}

function assign_data_source_ids_to_attachment_fields() {
  $('.nested-fields.attachment-fields:visible').each(function() {
    documentTypeId = $(this).find('.conditional-source.document_type_field').attr('id');
    $(this).find('.conditional-data.name_field').attr('data-source-id', documentTypeId);
  });
}

function assign_data_source_ids_to_facility_fields() {
  $('.nested-fields.facility-fields:visible').each(function() {
    accessibilityId = $(this).find('.conditional-source.accessibility_field').attr('id');
    $(this).find('.conditional-data.accessibility_medium_field').attr('data-source-id', accessibilityId);
  });
}

$(document).on('turbolinks:load', function() {
  assign_nested_conditional_field_ids();
  sync_conditional_fields();
  bind_conditional_fields();
});

$(document).on('cocoon:after-insert', function() {
  assign_nested_conditional_field_ids();
  sync_conditional_fields();
  bind_conditional_fields();
});
