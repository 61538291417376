$(document).on('turbolinks:load', function() {
  ////////////////////////////////////////////////////////////////
  // Refreshable nav-link

  $(document).on('click', '.nav-link-refreshable', function(event){
    target = event.target.closest('.nav-link-refreshable');
    document.location.hash = target.id;
  });

  hash = document.location.hash;
  $nav_link = $(`${hash}.nav-link-refreshable`);
  if(hash.length && $nav_link.length){
    $nav_link.tab('show');
  }

  ////////////////////////////////////////////////////////////////
  // Records

  $(document).on('click', '.record .fa-edit', function(event){
    $(event.target).parents('.record').addClass('glow')
  });

  $(document).on('hidden.bs.modal', '.modal', function(event){
    $(event.target).parents('.record').removeClass('glow')
  });

  ////////////////////////////////////////////////////////////////
  // Patient List

  $(document).on('click', '[data-js=vaccinated]', function(event){
    $target = $(event.target);
    patient_id = $target.data('patient');
    provider_id = $target.data('provider-enrollment');
    $modal = $(`#vaccinated_patient_${patient_id}`);
    checked = $target.prop('checked');

    if(checked) {
      $target.prop('checked', false);
      $modal.modal('show');
    }else {
      $.ajax({
        url: `/provider_enrollments/${provider_id}/patient_imports/${patient_id}/unvaccinate`,
        method: 'PATCH'
      })
      location.reload();
    }
  });
  ////////////////////////////////////////////////////////////////
})
