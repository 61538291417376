$(document).on("turbolinks:load", function() {
  $("a.audits-show-more").on("click", function(event) {
    event.preventDefault();

    let linkText = $(this).text();
    $(this).parent().find("ul").toggleClass("show-all");
    if(linkText == "Show More") {
      linkText = "Show Less";
    } else {
      linkText = "Show More";
    }
    $(this).text(linkText);
  });
});