function toggle_shift_select() {
  $("[data-js=shift_toggle]").on('change', function(event){
    let day      = $(event.currentTarget).data('day');
    let $select  = $(`.available_times_row .${day}`)
    let $shift   = $(`select[data-day=${day}]`);
    let $destroy = $(`input[type=checkbox][data-js=destroy][data-day=${day}]`)

    if(event.currentTarget.checked) {
      $destroy.prop('checked', false);
      $shift.find('option[value=""]').remove()
      $shift.find('option:eq(0)').prop('selected', true);
      $select.removeClass('d-none');
    } else {
      $destroy.prop('checked', true);
      $select.addClass('d-none');
      $shift.prepend('<option value></option>')
      $shift.find('option:eq(0)').prop('selected', true);
    }
  })
}

function sort_available_times_rows_by_day() {
  let $wrapper = $('#available_times_wrapper');
  let days     = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  $.each(days, function(_index, value){
    available_times_row = $wrapper.find(`.available_times_row[data-row=${value}]`);
    $wrapper.append(available_times_row);
  })
}

$(document).on('turbolinks:load', function() {
  toggle_shift_select();
  sort_available_times_rows_by_day();
});
